import React, { useState, useEffect } from "react"
import { Select } from "grommet"

const DateTimePicker = ({ onChange, options, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState(
    initialValue || options[0].value
  )
  useEffect(() => {
    onChange && onChange(selectedValue)
  }, [selectedValue, onChange])

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={({ option }) => setSelectedValue(option.value)}
      labelKey="label"
      valueKey={{ key: "value", reduce: true }}
    />
  )
}

export default DateTimePicker
