import React from "react"
import Picker from "./"
import { TWENTY_FOUR_MOUNTAIN } from "../../cm-lib/constants"

const Period = ({ onChange }) => {
  const options = TWENTY_FOUR_MOUNTAIN.map(e => ({
    label: e.facing.toUpperCase(),
    value: e.facing,
  }))
  return (
    <>
      <Picker options={options} onChange={onChange} />
    </>
  )
}

export default Period
