import React, { useState } from "react"
import FlyingStarChart from "../components/FlyingStar"
import { createFlyingstarChart } from "../cm-lib/XuanKongFlyingStar"
import PeriodPicker from "../components/Picker/Period"
import DirectionPicker from "../components/Picker/Direction"

const Home = ({ onDateChanged, date, handleSearch }) => {
  const [period, setPeriod] = useState(8)
  const [facing, setFacing] = useState("s2")
  return (
    <>
      <h2>Flying Star</h2>
      <DirectionPicker onChange={setFacing} />
      <PeriodPicker onChange={value => setPeriod(value)} />
      <br />
      <FlyingStarChart chart={createFlyingstarChart(facing, period)} />
    </>
  )
}

export default Home
