import React from "react"
import Picker from "./"

const Period = ({ onChange }) => {
  const options = Object.keys([...Array(9).keys()]).map(e => ({
    label: parseInt(e) + 1,
    value: parseInt(e) + 1,
  }))
  return (
    <>
      <Picker initialValue={8} options={options} onChange={onChange} />
    </>
  )
}

export default Period
