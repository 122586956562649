import React from "react"
import Layout from "../../../containers/layout"
import FlyingStar from "../../../containers/FlyingStar"

const Home = () => {
  return (
    <Layout>
      <FlyingStar />
    </Layout>
  )
}

export default Home
